.add-ip-form_div{
  border-bottom: 1px solid rgb(209 213 219);
  margin: 8px 0px;
}

.add-ip-form-information{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.add-ip-form-wrapper-input{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.add-ip-form-title{
  font-size: 20px;
  line-height: 32px;
  color: rgb(31 41 55);
  font-weight: 700;
  margin-left: 8px;
}

.add-ip-form-err_div{
  width: 335px;
}

.add-ip-form-err_div2{
  width: 236px;
}

.add-ip-form-err_div3{
  width: 410px;
}

.add-ip-form-wrapper-err{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
