footer {
  background: #006eaa;
  height: auto;
  width: 100%;
  color: #fff;
  padding: 13px 0;
  height: 5vh;
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

.footer-container h3 {
  font-size: 2.8rem;
  font-weight: 900;
}

.footer-container p {
  font-size: 18px;
  word-spacing: 2px;

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
  }
}
