.dialogTitle {
  cursor: move;
  font-size: 15px!important;
  background: #f8f7f7;
  padding: 8px 100px 8px 8px !important;
  font-weight: bold;
}

.dialogAction {
  background: #f8f7f7;
  padding: 4px!important;
}

.dialogAction button{
  padding: 2px 8px;
}

.dialogOverlayClass {
  position: absolute; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; 
  height: calc(100% - 40px);
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); 
  z-index: 5000;
  cursor: progress;
}

.dialogLoadingPanel {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 45%;
  flex-direction: row;
  width: auto;
  height: 40px;
  justify-content: left;
}

@keyframes loadingTextAnimation {
  0% {color: white;}
  25% {color: rgb(206, 203, 203);}
  50% {color: white;}
  75% {color: rgb(206, 203, 203);}
  100% {color: white;}
}

.dialogLoadingPanel .dialogLoadingText {
  margin-left: 8px;
  font-style: italic;
  user-select: none;
  color: white;
  margin-top: 10px;
  font-weight: bold;
  animation: loadingTextAnimation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}