header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

header ol,
header ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

header a {
  text-decoration: none;
}


.header-nav {
  width: 100%;
  background-color: white;
  border-color: rgb(229 231 235);
  padding: 10px 0;
  height: 72px;
  box-shadow: 0 1px 0 rgb(229 231 235);
  transition: height 2s;
  @media (min-width: 320px) and (max-width: 767px) {
    height: max-content;
  }
}

.header-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 5%;

  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    margin: 0 2%;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    margin: 0 0%;
  }
}

.header-link {
  display: flex;
  align-items: center;
  @media (min-width: 320px) and (max-width: 767px) {
    margin-right: auto;
  }
}

.header-link_img {
  height: 48px;
  @media (min-width: 320px) and (max-width: 767px) {
    margin-left: 2px;
  }
}

.header-link_span {
  align-self: center;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  white-space: nowrap;
  color: rgb(2 132 199);

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 16px; 
  }

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 15px;
  }
  
}

.header-version_span {
  align-self: center;
  line-height: 28px;
  font-weight: 600;
  white-space: nowrap;
  color: rgb(2 132 199);
  font-size: 12px;
  margin-top: 5px;
  margin-left: 6px;
  cursor: pointer;
}

.header-auth {
  display: flex;
  align-items: center;
  order: 2;

  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
    max-height: 0;
  }
}

.header-auth_login-btn {
  color: rgb(2 132 199);
  background-color: #fff;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
  font-weight: 500;
  margin-right: 16px;
  border-radius: 8px;
}

.header-auth_login-btn:hover {
  background-color: rgb(241 245 249);
  outline: 1px solid #0284C7;
}

.header-auth_register-btn {
  color: #fff;
  background-color: rgb(2 132 199);
  font-weight: 500;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
}

.header-auth_register-btn:hover {
  background-color: #fff;
  color: #0284C7;
  outline: 1px solid #0284C7;
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  order: 1;

  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
    max-height: 0;
  }
}

.header-wrapper_ul {
  display: flex;
  flex-direction: row;
  font-weight: 500;

  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
    max-height: 0;
  }
}

.header_ul_link {
  margin-left: 32px;
  display: block;
  color: rgb(55 65 81);

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 8px;
    font-size: 12px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    color: #fff;
    font-size: 14px;
    margin-left: 8px;
    text-decoration: none;
  }
}

.header_ul_link:hover {
  color: rgb(2 132 199);
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  text-decoration-color: red;
  animation: alternate 0.5s ease-in-out infinite;

  @media (min-width: 320px) and (max-width: 767px) {
    color: #ffffff;
  }
}

.link-focus {
  color: rgb(2 132 199);
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  text-decoration-color: red;

  @media (min-width: 320px) and (max-width: 767px) {
    color: #ffffff;
  }
}

.notification-dropdown-btn {
  padding: 8px;
  color: #006eaa;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  font-size: 26px;
  display: flex;
}

.notification-dropdown-btn:hover {
  color: #111827;
  background-color: rgb(210, 231, 252);
}

.unread-span {
  position: absolute;
  color: white;
  font-weight: 600;
  top: 24%;
  right: 7%;
  border-radius: 9999px;
  padding: 2px;
  font-size: 10px;
  background-color: red;
  outline: 1px solid white;
  min-width: 16px;
  height: 16px;
  text-align: center;
}

.header-mobile {
  display: none;
  @media (min-width: 320px) and (max-width: 767px) {
    display: block;
    order: 3;
  }
}
