.name-ip {
  text-decoration: none;
  color: blue !important;
  cursor: pointer;
}

.license-info {
  color: #000;
  border-radius: 4px;
  width: 100%;
  text-decoration: none;
  cursor: text;
  background-color: #e7e7e7;
  border: solid 1px #cfcfcf;
  margin-bottom: 8px;
}

.license-info-row{
  display: flex;
  justify-content: space-between;
  margin: 4px;
}

.license-info-label{
  color: #000;
  font-weight: bold;
}

.license-info-value{
  color: #000;
}
