.bug-details-container{
  width: 100%;
  height: 100%;
  margin-top: 4px;
}

.bug-details-wrapper-title{
  display: flex;
  align-items: center;
  width: 72%;
}

.bug-details-wrapper-title2{
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
}

.bug-details-body{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  @media (min-width: 320px) and (max-width: 767px) {
  }
}

.bug-details-content{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  margin-right: 30px;
  margin-bottom: 4px;
  margin-top: 10px;
  overflow-x: auto;
}

.bug-details-content2{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  overflow-x: auto;
}

.bug-details-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color:rgb(249 250 251);
  width: 100%;
  border: 1px solid rgb(209 213 219);
  border-radius: 4px;
  height: 100%;
  overflow-x: auto;
}
.bug-details-name{
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  font-weight: 500;
}

.bug-details-name2{
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  font-weight: 600;
  color: rgb(55 65 81);
  margin-right: 4px;
}

.bug-details-content-div-wrapper{
  width: 100%;
}

.bug-details-content_div_p{
  font-size:  16px;
  line-height:  24px;
  color: rgb(31 41 55);
  margin-top: 20px;
}

.bug-details-content_div_span{
  color: rgb(75 85 99);
  font-weight: 600;
  font-size:  16px;
  line-height:  24px;
}

.bug-details-versions{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px;
  width: 35%;

  @media (min-width: 320px) and (max-width: 767px) {
    visibility: hidden;
    max-height: 0;
  }
}

.bug-details-versions-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  width: 100%;
}

.bug-details-versions-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.bug-details-comment-time{
  font-size: 0.750rem;
  font-weight: 400;
  font-style: italic;
}

.bug-details-wrapper-p{
  width: 100%;
  border-bottom: 1px solid rgb(209 213 219);
  padding: 8px 0;
  display: flex;
  flex-direction: column;
}

.bug-details-wrapper-p2{
  width: 100%;
  border-bottom: 1px solid rgb(209 213 219);
  padding: 8px 0;
  display: flex;
  align-items: center;
}

.bug-details-wrapper-p3{
  width: 100%;
  border-bottom: 1px solid rgb(209 213 219);
  display: flex;
  background-color: #f6f8fa;
  justify-content: space-between;
}

.bug-details-comment-wrapper{
  display: flex;
  flex-direction: column;
  width: 98%;
}

.bug-details-comment-wrapper2{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow-y: visible;
  margin-top: 12px;
}

.bug-comments-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.bug-comment-container{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  margin-right: 30px;
  margin-bottom: 8px;
  margin-top: 10px;
  overflow-x: auto;
}

.bug-details-issue-tittle{
  display: flex;
  width: 82%;
  @media (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
}

.bug-details-issue-title-input {
  font-size: 20px;
  padding: 2px 8px;
  background-color: #f9f9f9;
  width: 90%;
  border: 1px solid rgb(209 213 219);
  border-radius: 4px;
}

.bug-details-issue-tittle-h2{
  font-size: 20px;
  margin-right: 20px;
  width: 90%;
  word-wrap: break-word;
  @media (min-width: 320px) and (max-width: 767px) {
    margin-right: 0px;
    font-size: 16px;
  }
}

.bug-details-edit-span{
  cursor: pointer;
  color: #0284C7;
  font-size: 14px;
  font-weight: 600;
  align-self: center;
  padding: 8px;
}

.bug-details-edit-span:hover{
  background-color: #f9f9f9;
  border-radius: 4px;
}

.mr_span {
  margin-right: 12px;
}

.issue-setup-fields-wrapper{
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.issue-detail-fields-wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.issue-description-fields-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
}

.field-name_span {
  color: rgb(75 85 99);
  font-weight: 600;
  font-size:  16px;
  line-height:  24px;
  width: 100px;
}

.issue-type-wrapper {
  display: flex;
  align-items: center;
}

.issue-type_span {
  margin-left: 8px;
}

.required-issue-field_span {
  color: red;
}

.error-issue-field_span {
  color: red;
  margin-left: 108px;
  font-size: small;
  font-weight: 500;
}

.bug-details-wrapper-p{
  width: 100%;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
}

.jira-logo-container{
  display: flex;
  justify-content: center;
  align-self: center;
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.bug-details-bug-btn{
  padding: 4px 8px;
  width: 200px;
  font-size:  16px;
  line-height:  24px;
  font-weight: 500;
  color: white;
  background-color:rgb(31 41 55);
  align-self: flex-end;
  cursor: pointer;
  border-radius: 6px;
}

.bug-details-bug-btn:hover{
  background-color: white;
  color: rgb(31 41 55);
}
