/* Share */
.ip-details-content_div_p{
  font-size: 16px;
  line-height:  24px;
  color: rgb(31 41 55);
}

.ip-details-content_div_p2{
  font-size: 12px;
  color: #374151;
  margin-top: 4px;
}

/* Share */
.ip-details-content_div_span{
  color: rgb(107 114 128);
  font-weight: 600;
  font-size:  14px;
  line-height:  24px;
  width: 105px;
}

.ip-details-content_div_span2{
  color: rgb(107 114 128);
  font-weight: 600;
  font-size:  12px;
}

/* Share */
.ip-details-content_div2{
  display: flex;
  width: 100%;
}

/* Share */
.ip-details-content_div_wrapper_3{
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;
  width: 20vw;
  height: auto;
}

/* Share */
.ip-details-content_div{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

/* Share */
.ip-details-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color:rgb(249 250 251);
  padding: 16px;
  width: 100%;
}

.margin-top-bottom-10 {
  margin: 10px 0;
}
