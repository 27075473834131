.new-ip-fdiv {
  display: block;
  width: 100%;
}

.new-ip-auto-div {
  display: block;
  width: calc(100% - 20px);
  height: auto;
  margin-top: 6px;
  margin-bottom: 8px;
}

.error_span{
  color: rgb(211,47,47);
  font-size: 13px;
  font-weight: 600;
}
