.login {
  display: flex;
  justify-content: center;
}

.container {
  width: 80vw;
  height: 80vh;
  border: 1px solid #ececec;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
