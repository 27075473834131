.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  @media (min-width: 768px) and (max-width: 1023px){
    padding: 0 16px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0 12px;
  }
}

.login-form-container {
  width: 30%;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

  @media (min-width: 768px) and (max-width: 1023px){
    width: 80%;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
}

.login-form-wrapper {
  padding: 24px;
  font-size: 20px;
  line-height: 28px;
}

.login-form_title {
  font-size: 28px;
  line-height: 1.25;
  font-weight: 700;
  letter-spacing: -0.025em;
  color: rgb(17 24 39);
}

.login-form_label {
  display: block;
  margin: 8px 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #111827;
}

.login-form-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.login-form-box-container {
  display: flex;
  align-items: flex-start;
}

.login-form-box_wrapper {
  display: flex;
  align-items: center;
  height: 20px;
}

.login-form-box_input {
  width: 16px;
  height: 16px;
  border: 1px solid rgb(203 213 225);
  border-radius: 4px;
  background-color: rgb(248 250 252);
}

.login-form-box_label {
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
}

.login-form-remember-me {
  color: rgb(107 114 128);
}

.login-form-dont_have_account {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: rgb(75 85 99);
  margin-top: 16px;
}

.login-form-signup-btn {
  font-weight: 500;
  text-decoration: none;
  color: rgb(2 132 199);
  margin-left: 5px;
}

.login-form-signup-btn:hover {
  text-decoration: underline;
}