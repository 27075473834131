/* @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap"); */
@import "@fontsource/roboto/index.css";
@import "assets/css/ipProduct.css";
@import "assets/css/bugDetails.css";
@import "assets/css/addNewIP.css";
@import "assets/css/addIpForm.css";

* {
  font-family: "Roboto";
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


.viewSwitcher {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 10;
  background: gray;
  padding: 5px;
  border-radius: 5px;
}

.viewSwitcher > label {
  font-weight: 600;
  margin-right: 3px;
}

.divBlk100 {
  display: block;
  width: 100%
}

.collapse-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 0px 2px #ccc;
  margin-bottom: 8px;
}

.markdown-content li {
  margin-left: 20px;
}

/* Customize scrollbar on desktop */

/* Width */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
  transition: background 0.3s ease-in-out;
}

::-webkit-scrollbar-track:hover {
  background: #e1e1e1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 10px;
  transition: background 0.3s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #757575;

}

/* Handle active */
::-webkit-scrollbar-thumb:active {
  background: #616161;
}

/* For Firefox */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: #9e9e9e #f1f1f1;
  }
}

.jodit-toolbar__box {
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
}