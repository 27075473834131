.notifications-header {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: rgb(55 65 81);
  background-color: rgb(249 250 251);
  text-align: center;
  padding: 8px 12px 8px 12px;
}

.notification-item-link {
  display: flex;
  border-bottom: 1px solid rgb(229 231 235);
  text-decoration: none;
  padding: 12px;
  align-items: center;
  cursor: pointer;
}

.notification-item-link:hover {
  background-color: #f3f4f6;
}

.notification-wrapper {
  width: 100%;
  padding-left: 12px;
  overflow: hidden;
}

.notification-content {
  font-weight: 400;
  color: rgb(107 114 128);
  font-size: 13px;
  line-height: 1rem;
  overflow-y: hidden;
  margin-bottom: 4px;
  height: 46px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}

.unread-notification-content {
  font-weight: 400;
  color: rgb(55 65 81);
  font-size: 13px;
  line-height: 1rem;
  overflow-y: hidden;
  margin-bottom: 4px;
  height: 46px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

.unread-notification-text {
  font-weight: 600;
  color: #111827;
}

.notification-text {
  font-weight: 600;
  color: rgb(107 114 128);
}

.notifications-footer-link {
  padding: 4px 0 4px 0;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  color: #111827;
  background-color: rgb(249 250 251);
  display: block;
}

.notifications-footer-wrapper {
  display: inline-flex;
  align-items: center;
}

.notifications-footer-svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.notifications-footer-link:hover {
  background-color: #f3f4f6;
}

.unread-notification-time {
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgb(29 78 216);
  font-weight: 600;
}

.notification-time {
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgb(107 114 128);
  font-weight: 600;
}

.unread-notification-synced {
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgb(107 114 128);
  font-weight: 600;
}

.notification-synced {
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgb(107 114 128);
  font-weight: 500;
}

.notification-dialog {
  background-color: white;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-color: #f3f4f6;
  border-radius: 0.25rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  width: 24rem;
  position: absolute;
  z-index: 999;
  right: 4%;
  top: 60px;

  @media (max-width: 767px) {
    width: 100%;
    right: 0;
  }
}

.notification-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-page-container {
  background-color: white;
  border: 1px solid rgb(229 231 235);
  border-radius: 0.25rem;
  width: 40rem;
}

.notification-page-footer {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  color: #111827;
  background-color: rgb(249 250 251);
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-item-download-link {
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgb(29 78 216);
  font-weight: 600;
  text-decoration: none;
}

.notification-item-btn {
  padding: 2px 4px;
  width: 80px;
  font-size:  12px;
  line-height:  20px;
  font-weight: 500;
  color: white;
  background-color:rgb(3 105 161);
  align-self: flex-end;
  cursor: pointer;
  border-radius: 6px;
  border: none;
  outline: 1px solid rgb(3 105 161);
}

.notification-item-btn:hover {
  background-color: white;
  color: rgb(3 105 161);
}

.notification-item-link .hidden-child{
  visibility: hidden;
}

.notification-item-link:hover .hidden-child{
  visibility: visible;
}

.notifications-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px;
  font-size: 100px;
  color: rgb(107 114 128);
}

.no-notifications_p {
  font-size: 20px;
  font-weight: 600;
}