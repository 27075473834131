.menu-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-button-container .basic-menu-btn {
  height: fit-content;
  font-size: 14px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  border-radius: 20px;
  color: #006eaa;
  padding: 10px 15px;

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 12px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
}

.menu-button-container .basic-menu-btn:hover {
  background-color: rgb(210, 231, 252);
}

div div:has(.menu-item) {
  box-shadow: none;
  border-radius: 5px;
  background-color: #006eaa;
  color: white;
}

#basic-menu {
  background-color: transparent;
}
.menu-item {
  width: 200px;
  font-size: 18px;
}

.menu-item:nth-child(n):hover {
  background-color: rgb(74, 159, 243);
}
